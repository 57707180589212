import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import Breadcrumbs from "components/Breadcrumbs"
import Card from "components/Card"
import CardHolder from "components/CardHolder"
import pngBDO from "assets/bdo.png"
import pngGenshin from "assets/genshin.png"
import webpBDO from "assets/bdo.webp"
import webpGenshin from "assets/genshin.webp"

export default function Home() {
	return (
		<>
			<Helmet>
				<title>hemlo.cc app catalogue</title>
			</Helmet>
			<Breadcrumbs
				path={[
					{
						label: "Home",
					}
				]}
			/>
			<article>
				<h1>Welcome to hemlo.cc</h1>
				<p>
					Welcome to hemlo.cc (/ˈhemlɒk/), a website where I host some of the apps that I develop as a hobby.
				</p>

				<h2>Apps</h2>
				<p>
					All of the apps' source code is available on <a href="https://github.com/man90es" rel="noreferrer" target="_blank">my GitHub page</a>. If you like them, like me, or just want to help keeping them up, consider contributing or visit <Link to="./finances">the finances page</Link>.
				</p>

				<CardHolder>
					<Card
						children="An AI-assisted party creation tool for Genshin Impact."
						href="https://genshin.hemlo.cc/partybuilder/"
						image={[
							{ mime: "image/webp", src: webpGenshin },
							{ mime: "image/png", src: pngGenshin },
						]}
						title="Party Builder for Genshin Impact"
					/>
					<Card
						children="Web-based leaderboards app for Black Desert Online."
						href="https://bdo.hemlo.cc/leaderboards/"
						image={[
							{ mime: "image/webp", src: webpBDO },
							{ mime: "image/png", src: pngBDO },
						]}
						title="BDO leaderboards"
					/>
					<Card
						children="Scraper for Black Desert Online community data with a built-in API server."
						href="https://github.com/man90es/BDO-REST-API/"
						image={[
							{ mime: "image/webp", src: webpBDO },
							{ mime: "image/png", src: pngBDO },
						]}
						title="BDO REST API"
					/>
				</CardHolder>

				<h2>Contact</h2>
				<p>
					If you need to contact me for any reason, like offering a partnership, confessing your love, or just a quick chat, you can:
				</p>
				<ul>
					<li>write an email to <a href="mailto:man90@hemlo.cc">man90@hemlo.cc</a></li>
					<li>send a message to <a href="https://discordapp.com/users/1110852211363287081" rel="noreferrer" target="_blank">@man90es</a> on Discord</li>
					<li>tag <a href="https://twitter.com/man90es" rel="noreferrer" target="_blank">@man90es</a> on Twitter</li>
				</ul>
				<p>
					I usually reply to everyone within a few days.
				</p>
			</article >
		</>
	)
}
