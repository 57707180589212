import classes from "./Card.module.css"
import type { ReactNode } from "react"

type CardParams = {
	children: ReactNode
	href: string
	image: Array<{ mime: string, src: string }>
	title: ReactNode
}

export default function Card({ children, href, title, image }: CardParams) {
	return (
		<a
			className={classes.root}
			href={href}
			rel="noreferrer"
			target="_blank"
		>
			<picture>
				{image.map(({ mime, src }) => (
					<source type={mime} srcSet={src} key={mime} />
				))}
				<img alt="" className={classes.image} src={image.at(-1)?.src} />
			</picture>
			<h3 children={title} />
			<p children={children} />
		</a>
	)
}
