import "normalize.css"
import "sakura.css"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { StrictMode } from "react"
import classes from "./App.module.css"
import Finances from "pages/Finances"
import Home from "pages/Home"

const router = createBrowserRouter([
	{
		path: "/",
		element: <Home />,
	},
	{
		path: "/finances",
		element: <Finances />,
	},
])

export const metadata = {
	title: "hemlo.cc app catalogue",
	description: "hemlo.cc is a website where I host some of the apps that I develop as a hobby.",
}

export default function App() {
	return (
		<StrictMode>
			<RouterProvider router={router} />
			<footer
				children={`© ${(new Date()).getFullYear()} man90`}
				className={classes.footer}
			/>
		</StrictMode>
	)
}
