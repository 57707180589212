import { Helmet } from "react-helmet"
import Breadcrumbs from "components/Breadcrumbs"

export default function Finances() {
	return (
		<>
			<Helmet>
				<title>hemlo.cc finances</title>
			</Helmet>
			<Breadcrumbs
				path={[
					{
						href: "/",
						label: "Home",
					},
					{
						label: "Finances",
					},
				]}
			/>
			<article>
				<h1>hemlo.cc finances</h1>
				<a id="finances" />
				<p>
					Thank you for using apps available on hemlo.cc, such ass <a href="https://genshin.hemlo.cc/partybuilder/my-parties">Party Builder for Genshin Impact</a> and <a href="https://bdo.hemlo.cc/leaderboards/">Black Desert Leaderboards</a>! This page shows different ways to support the website.
				</p>

				<h2>Donate</h2>
				<a id="donate" />
				<p>
					Hemlo.cc is currently run entirely out of my pocket: it doesn't serve ads, and all services are available free of charge. You can support it by directly donating me money:
				</p>
				<ul>
					<li><a href="https://github.com/sponsors/man90es">Sponsor me on GitHub</a></li>
					<li><a href="bitcoin:bc1qq0r7wx4r70z2tnx2a9uwm2derlg7qa0x2kcxx8">Send me some Bitcoin</a></li>
					<li><a href="https://ko-fi.com/man90">Buy me a coffee</a></li>
				</ul>
				<p>
					You can also help by using this affiliate link to my VPS hosting provider, Xenyth: <a href="https://xenyth.net/?affid=448" target="_blank" children="https://xenyth.net/?affid=448" rel="noreferrer" />. They provide great cheap servers and I've been relying on them since early 2022. Moreover, when you register via my link and make your first deposit, I get up to 2 months worth of free hosting.
				</p>
				<p>
					Any financial support would allow me to keep my apps available for everyone and constantly improve them.
				</p>
			</article>
		</>
	)
}
