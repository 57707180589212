import classes from "./CardHolder.module.css"
import type { ReactNode } from "react"

type CardHolderParams = {
	children: ReactNode
}

export default function CardHolder({ children }: CardHolderParams) {
	return (
		<div className={classes.root} children={children} />
	)
}
