import { Fragment } from "react"
import { Link } from "react-router-dom"
import classes from "./Breadcrumbs.module.css"

type BreadcrumbPath = {
	href?: string
	label: string
}[]

export default function Breadcrumbs({ path = [] }: { path: BreadcrumbPath }) {
	return (
		<div className={classes.root}>
			{path.map(({ href, label }, i) => (
				<Fragment key={label}>
					{Boolean(i) &&
						<div children="/" className={classes.separator} />
					}
					{href
						? <Link children={label} to={href} />
						: <div children={label} className={classes.nonLink} />
					}
				</Fragment>
			))}
		</div>
	)
}
